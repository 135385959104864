<template>
  <div>
    <div class="title">认识亚盘</div>
    <el-divider></el-divider>
    <div class="simple">
      近来赛事较多，五大联赛和欧洲国家联赛已经激发了彩民的热情，很快欧冠也要开赛，可玩的比赛越来越多。不过，新彩民虽然买彩热情上来了，但有时看专家分析时，对于什么盘口让球，升水降水什么的还是不甚了解。而了解亚盘有助于我们精准预测比赛。
    </div>
    <div class="body">
      亚洲盘口简称亚盘，它的存在形态是通过让球盘口使两个相差悬殊的球队间能够在一个相对公平的平台上进行较量，从而拉平双方客观存在的实力差距。这种盘口也可称为独赢盘。由于这种玩法起源于亚洲，因此又被称为亚洲盘口。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
</style>
